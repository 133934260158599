import Wrapper from "./layout/wrapper";
import Home10 from "./home/home_10";

const MainRoot = () => {
  return (
    <Wrapper>
      <Home10 />
    </Wrapper>
  );
};

export default MainRoot;
